import { useState, useEffect } from "react";

import {
  TextField,
  MenuItem,
  Grid,
  Stack,
  Switch,
  Typography,
  Autocomplete,
  Divider,
} from "@pankod/refine-mui";
import { Controller } from "@pankod/refine-react-hook-form";
import MultiSelect from "components/MultiSelect";
import UploadZone from "components/UploadZone";
import GalleryManager from "components/GalleryManager";

export default function CompFields({
  fieldVariants,
  label,
  name,
  variant,
  required,
  defaultValue,
  value,
  type,
  options,
  accessor,
  lines,
  xs,
  md,
  height,
  register,
  control,
  errors,
  setError,
  getValues,
  setValue,
  watch,
  viewOnly,
}) {
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  useEffect(() => {
    if (
      (type == "autocomplete" || type == "multi-select") &&
      defaultValue &&
      autocompleteValue == null
    ) {
      setValue(name, defaultValue, { shouldValidate: true });
      setAutocompleteValue(defaultValue);
    }
  }, [defaultValue]);

  const defaultValueData = watch(name);

  if (type == "title")
    return (
      <Grid item xs={12} sx={{ height: height || "100%" }}>
        <Typography variant={variant} gutterBottom component="div">
          {label}
        </Typography>
        <Divider sx={{ mb: 2 }} />
      </Grid>
    );
  if (type == "space")
    return <Grid item xs={12} sx={{ height: height || "100%" }}></Grid>;
  if (type == "divider")
    return (
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        sx={{ height: height || "100%" }}
      >
        <Grid item xs={xs || 12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
      </Grid>
    );
  if (
    type == "text" ||
    type == "number" ||
    type == "date" ||
    type == "time" ||
    type == "datetime"
  )
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        {viewOnly && (
          <Grid item {...register(name)}>
            <Typography variant="subtitle1" color="text.secondary">
              {label}
            </Typography>
            <Typography variant="subtitle1" color="text.primary">
              {value || defaultValue || defaultValueData}
            </Typography>
          </Grid>
        )}
        {!viewOnly && (
          <TextField
            {...register(name, {
              required: required ? label + " Required" : false,
            })}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors[name]}
            helperText={errors[name]?.message}
            required={required}
            variant={fieldVariants || "standard"}
            defaultValue={defaultValue || defaultValueData}
            fullWidth
            label={label}
            type={type == "datetime" ? "datetime-local" : type}
            multiline={lines && lines > 1}
            rows={lines}
          />
        )}
      </Grid>
    );

  if (type == "switch")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <Stack direction="row" alignItems="center" spacing={2} mt={3}>
          <Typography variant="subtitle1" color="text.secondary">
            {label}
          </Typography>
          {viewOnly && (
            <Grid item {...register(name)}>
              <Typography variant="subtitle1" color="text.primary">
                {value || defaultValue || defaultValueData ? "Yes" : "No"}
              </Typography>
            </Grid>
          )}
          {!viewOnly && (
            <Switch
              {...register("is_included", {})}
              control={control}
              checked={defaultValue || defaultValueData}
            />
          )}
        </Stack>
      </Grid>
    );
  if (type == "select")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        {viewOnly && (
          <Grid item {...register(name)}>
            <Typography variant="subtitle1" color="text.secondary">
              {label}
            </Typography>
            <Typography variant="subtitle1" color="text.primary">
              {value || defaultValue || defaultValueData}
            </Typography>
          </Grid>
        )}
        {!viewOnly && (
          <TextField
            {...register(name, {
              required: required ? label + " Required" : false,
            })}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors[name]}
            helperText={errors[name]?.message}
            required={required}
            variant={fieldVariants || "standard"}
            defaultValue={defaultValue}
            fullWidth
            label={label}
            select
            value={value || ""}
            onChange={(e) => {
              console.log(e.target.value);
              setValue(name, e.target.value, { shouldValidate: true });
            }}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Grid>
    );
  if (type == "autocomplete")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        {viewOnly && (
          <Grid item {...register(name)}>
            <Typography variant="subtitle1" color="text.secondary">
              {label}
            </Typography>
            <Typography variant="subtitle1" color="text.primary">
              {value || defaultValue || defaultValueData}
            </Typography>
          </Grid>
        )}
        {!viewOnly && (
          <Controller
            control={control}
            name={name}
            rules={{ required: required ? label + " Required" : false }}
            render={({ field }) => (
              <Autocomplete
                {...options}
                {...field}
                value={field.value || autocompleteValue}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                getOptionLabel={(item) => {
                  return item[accessor] ? item[accessor] : "";
                }}
                isOptionEqualToValue={(option, value) => {
                  return value === undefined || option.id === value.id;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    variant={fieldVariants || "outlined"}
                    error={!!errors[name]}
                    helperText={errors[name]?.message}
                    required
                  />
                )}
              />
            )}
          />
        )}
      </Grid>
    );
  if (type == "multi-select")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        {viewOnly && (
          <Grid item {...register(name)}>
            <Typography variant="subtitle1" color="text.secondary">
              {label}
            </Typography>
            <Typography variant="subtitle1" color="text.primary">
              {value || defaultValue || defaultValueData}
            </Typography>
          </Grid>
        )}
        {!viewOnly && (
          <Controller
            control={control}
            name={name}
            render={({ field }) => {
              return (
                <MultiSelect
                  name={label}
                  variant={fieldVariants || "standard"}
                  options={options}
                  value={field.value || autocompleteValue}
                  defaultValue={defaultValue}
                  onChange={(val) => {
                    field.onChange(val);
                    setValue(name, val, { shouldValidate: true });
                  }}
                />
              );
            }}
          />
        )}
      </Grid>
    );
  if (type == "image")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <UploadZone
          register={register}
          errors={errors}
          setError={setError}
          setValue={setValue}
          defaultValue={defaultValue || defaultValueData}
          fieldName={name}
          required={required}
          disabled={viewOnly}
        />
      </Grid>
    );
  if (type == "gallery") {
    const value = watch(name);
    if (viewOnly && !value) return null;

    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <Controller
          control={control}
          name={name}
          rules={{ required: required ? label + " Required" : false }}
          render={({ field }) => {
            return (
              <GalleryManager
                fieldName={field.name}
                accessor={accessor}
                control={control}
                errors={errors}
                setError={setError}
                defaultValue={watch(field.name)}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
                viewOnly={viewOnly}
              />
            );
          }}
        />
      </Grid>
    );
  }
}
