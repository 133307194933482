import { useState, useEffect } from "react";

import {
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@pankod/refine-mui";

import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";

import { useParams } from "@pankod/refine-react-router-v6";
import axios from "utils/axios";
import { normalizeData } from "utils/utils";
import { toast } from "react-toastify";

function ViewRegistrationRequest() {
  const params = useParams();

  const [record, setRecord] = useState(null);

  const fetchRequest = async () => {
    const response = await axios.get(`/api/registration-requests/${params.id}`);
    setRecord(normalizeData(response.data));
  };

  useEffect(() => {
    fetchRequest();
  }, []);

  if (!record) return null;

  const onActivateAccount = (accept) => {
    try {
      axios.post("/api/activate-account", {
        registration_request: params.id,
        action: accept ? "accept" : "reject",
      });
      toast.success(`Request Updated`);
      fetchRequest();
    } catch (error) {
      toast.error("Si è verificato un errore");
    }
  };

  return (
    <Grid item xs={12} ml={4}>
      <Card sx={{ borderRadius: 8, padding: 3, width: "100%" }}>
        <Grid
          container
          spacing={2}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography variant="h3">Name:</Typography>
            <Typography variant="h4" ml={1}>
              {record.full_name}
            </Typography>
            <Typography mt={2} variant="h3">
              Business:
            </Typography>
            <Typography variant="h4" ml={1}>
              {record.business_name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="h5" ml={1}>
                Email:
              </Typography>
              <Typography variant="h5" ml={1}>
                {record.email}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="h5" ml={1}>
                Phone:
              </Typography>
              <Typography variant="h5" ml={1}>
                {record.phone}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="h5" ml={1}>
                Address:
              </Typography>
              <Typography variant="h5" ml={1}>
                {record.address}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="h5" ml={1}>
                UTR:
              </Typography>
              <Typography variant="h5" ml={1}>
                {record.vat}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <Divider sx={{ my: 2 }} />
      <Card sx={{ borderRadius: 8, padding: 3, width: "100%" }}>
        <Typography variant="h3">Message:</Typography>
        <Typography variant="body1">{record.message}</Typography>
      </Card>

      <Stack mt={2} direction="column" gap={1} ml={1}>
        <Typography variant="h6">Status: {record.status}</Typography>
        {record.status === "pending" && (
          <Stack direction="row" alignItems="center" gap={3}>
            <Button
              startIcon={<ThumbUpOffAltIcon />}
              variant="contained"
              color="success"
              onClick={() => onActivateAccount(true)}
            >
              APPROVE
            </Button>
            <Button
              startIcon={<ThumbDownOffAltIcon />}
              variant="contained"
              color="error"
              onClick={() => onActivateAccount(false)}
            >
              REJECT
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
}

export default ViewRegistrationRequest;
