import { Divider, useTheme } from "@mui/material";

const Component = ({ width, thickness, rounded }) => {
  const theme = useTheme();

  return (
    <Divider
      sx={{
        my: 2,
        width: width || "30%",
        mx: "auto",
        borderBottomWidth: thickness || "5px",
        borderBottomColor: theme.secondary.main,
        borderRadius: rounded || 3,
      }}
    />
  );
};

export default Component;
