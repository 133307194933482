import {
  DataGrid,
  List,
  Stack,
  Typography,
  DeleteButton,
  useDataGrid,
} from "@pankod/refine-mui";
import { Link } from "@pankod/refine-react-router-v6";
import { useEffect, useMemo } from "react";

import { useGetIdentity } from "@pankod/refine-core";

function SellerList() {
  const { data: user } = useGetIdentity();

  const columns = useMemo(
    () => [
      {
        field: "full_name",
        headerName: "Full Name",
        flex: 1,
        minWidth: 100,
        maxWidth: 300,
      },
      {
        field: "business_name",
        headerName: "Business Name",
        flex: 1,
        minWidth: 300,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        minWidth: 300,
      },
      {
        field: "status",
        headerName: "status",
        flex: 1,
        minWidth: 100,
        maxWidth: 300,
        renderCell: function render(p) {
          return <span>{p.row.status}</span>;
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(p) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Link to={`/registration-requests/${p.row.id}`}>VIEW</Link>
              <DeleteButton
                hideText
                resourceNameOrRouteName="registration-requests"
                recordItemId={p.row.id}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, setFilters } = useDataGrid({
    resource: "registration-requests",
  });

  return (
    <List
      resource="registration-requests"
      canCreate={false}
      headerProps={{
        title: <Typography variant="h5">Registration Requests</Typography>,
      }}
    >
      <DataGrid {...dataGridProps} columns={columns} autoHeight />
    </List>
  );
}

export default SellerList;
