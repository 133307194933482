import { useMemo } from "react";
import {
  EditButton,
  DeleteButton,
  Stack,
  Typography,
  useDataGrid,
  DataGrid,
  List,
} from "@pankod/refine-mui";

export default function CompList() {
  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 10,
      },
      {
        field: "businesses",
        headerName: "Businesses",
        flex: 1,
        minWidth: 10,
        renderCell: function render(params) {
          return <Stack direction="row">{params.row.businesses.length}</Stack>;
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: "*",
        sort: [
          {
            field: "id",
            order: "desc",
          },
        ],
      },
    });

  return (
    <List
      resource="categories"
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Lists</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
