import { Grid, IconButton } from "@mui/material";

import { PopOnHover } from "./Animate";

const socialLinks = [
  {
    name: "yelp",
    imageUrl:
      "https://s3-media0.fl.yelpcdn.com/assets/public/yelp_favicon.yji-70908133d95b444ea335.svg",
  },
  {
    name: "snapchat",
    imageUrl: "https://www.svgrepo.com/show/303130/snapchat-logo.svg",
  },
  {
    name: "linkedin",
    imageUrl:
      "https://content.linkedin.com/content/dam/me/business/en-us/amp/brand-site/v2/bg/LI-Bug.svg.original.svg",
  },
  {
    name: "pinterest",
    imageUrl: "https://www.svgrepo.com/show/155239/pinterest.svg",
  },
  {
    name: "tiktok",
    imageUrl: "https://www.svgrepo.com/show/333611/tiktok.svg",
  },
  {
    name: "twitter",
    imageUrl:
      "https://www.freepnglogos.com/uploads/twitter-logo-png/twitter-logo-vector-png-clipart-1.png",
  },
  {
    name: "youtube",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/1280px-YouTube_full-color_icon_%282017%29.svg.png",
  },
  {
    name: "facebook",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/2048px-Facebook_icon_2013.svg.png",
  },
  {
    name: "instagram",
    imageUrl:
      "https://www.freepnglogos.com/uploads/instagram-logos-png-images-free-download-2.png",
  },
];

const Component = ({ contactType, href, width }) => {
  let imageUrl = socialLinks.find(
    (socialLink) => socialLink.name == contactType
  )?.imageUrl;

  if (!imageUrl || !href) {
    return null;
  }

  return (
    <Grid
      item
      xs={width || 3}
      container
      justifyContent="center"
      alignItems="center"
    >
      <PopOnHover traslation="0 -5%" scaling="1.1">
        <IconButton
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            width: 32,
            height: 32,
            borderRadius: 0,
            p: 0,
          }}
          onClick={() => {
            window.open(href, "_blank");
          }}
        >
          <img
            src={imageUrl}
            alt={contactType}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </IconButton>
      </PopOnHover>
    </Grid>
  );
};

export default Component;
