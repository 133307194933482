import { Grid, Card, useTheme } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { useMobile } from "utils/utils";

const Component = ({ images, fit, marginTop, borderRadius, elevation }) => {
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      sx={{
        mt: {
          md: marginTop || 0,
        },
      }}
    >
      <Grid item xs={10}>
        <Card
          elevation={elevation}
          sx={{
            width: "100%",
            height: 300,
            borderRadius: 5,
            m: 0,
            p: 0,
          }}
        >
          <Carousel
            autoPlay
            infiniteLoop
            swipeable
            swipeScrollTolerance={350}
            height="100%"
          >
            {images.map((image, index) => (
              <div
                key={index}
                style={{
                  height: 300,
                }}
              >
                <img
                  src={image}
                  alt="banner"
                  style={{
                    objectFit: fit || "cover",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </div>
            ))}
          </Carousel>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Component;
