import { useMemo, useState } from "react";
import {
  EditButton,
  DeleteButton,
  Stack,
  Typography,
  useDataGrid,
  DataGrid,
  List,
  Grid,
  TextField,
  LoadingButton,
  IconButton,
  MenuItem,
} from "@pankod/refine-mui";

import { CheckCircle, Cancel } from "@mui/icons-material";

import { toast } from "react-toastify";
import axios from "utils/axios";

const OkIcon = () => <CheckCircle color="success" />;
const CancelIcon = () => <Cancel color="error" />;

export default function CompList() {
  const columns = useMemo(
    () => [
      {
        field: "notes",
        headerName: "Notes",
        flex: 1,
        minWidth: 10,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row.notes ? <OkIcon /> : <CancelIcon />}
            </Stack>
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Found On",
        flex: 1,
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Typography variant="body2">
              {params.row.createdAt.slice(0, 10) +
                " " +
                params.row.createdAt.slice(11, 16)}
            </Typography>
          );
        },
      },
      { field: "name", headerName: "Name", flex: 1, minWidth: 300 },
      {
        field: "website",
        headerName: "Website",
        flex: 1,
        minWidth: 10,
        maxWidth: 100,
        renderCell: function render(params) {
          return (
            <Grid container justifyContent="center">
              {params.row.website ? (
                <IconButton
                  onClick={() => {
                    window.open(params.row.website, "_blank");
                  }}
                  sx={{
                    width: 32,
                    height: 32,
                  }}
                >
                  <OkIcon />
                </IconButton>
              ) : (
                <CancelIcon />
              )}
            </Grid>
          );
        },
      },
      {
        field: "phone",
        headerName: "Phone",
        flex: 1,
        minWidth: 10,
        maxWidth: 100,
        renderCell: function render(params) {
          return (
            <Grid container justifyContent="center">
              {params.row.phone ? <OkIcon /> : <CancelIcon />}
            </Grid>
          );
        },
      },
      {
        field: "emails",
        headerName: "Emails",
        flex: 1,
        minWidth: 10,
        maxWidth: 100,
        renderCell: function render(params) {
          return (
            <Grid container justifyContent="center">
              {params.row.emails ? <OkIcon /> : <CancelIcon />}
            </Grid>
          );
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        sort: [
          {
            field: "id",
            order: "desc",
          },
        ],
      },
    });

  const [searching, setSearching] = useState(false);

  const searchBar = () => (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      component={"form"}
      rowSpacing={2}
      onSubmit={async (e) => {
        e.preventDefault();
        setSearching(true);
        let query = e.target.elements.query.value;
        let lang = e.target.elements.lang.value;
        let limit = e.target.elements.limit.value;
        try {
          let { data } = await axios.post(`/api/find-local`, {
            query,
            lang,
            limit,
          });
          toast.success("Search completed, found " + data.length + " results.");
          setSearching(false);
          await new Promise((resolve) => setTimeout(resolve, 2000));
          window.location.reload();
        } catch (error) {
          console.log(error);
          setSearching(false);
          toast.error("Error while searching.");
        }
      }}
    >
      <Grid item xs={12} lg={4}>
        <TextField
          label="Search"
          fullWidth
          size="small"
          variant="outlined"
          name="query"
        />
      </Grid>
      <Grid item xs={4} lg={2}>
        <TextField
          select
          label="Language"
          fullWidth
          size="small"
          variant="outlined"
          name="lang"
          defaultValue={"en"}
        >
          <MenuItem value={"en"}>English</MenuItem>
          <MenuItem value={"it"}>Italian</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={4} lg={2}>
        <TextField
          label="Limit"
          fullWidth
          size="small"
          variant="outlined"
          type="number"
          name="limit"
          InputProps={{ inputProps: { min: 1, max: 150 } }}
          defaultValue={50}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <LoadingButton
          loading={searching}
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
        >
          Search
        </LoadingButton>
      </Grid>
    </Grid>
  );

  return (
    <>
      <List
        resource="businesses"
        createButtonProps={{ size: "small" }}
        headerProps={{
          title: (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} lg={4}>
                <Typography variant="h5">Businesses</Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                {searchBar()}
              </Grid>
            </Grid>
          ),
        }}
        canCreate={false}
      >
        <DataGrid
          {...dataGridProps}
          columns={columns}
          sortingMode={sortingMode}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          autoHeight
        />
      </List>
    </>
  );
}
