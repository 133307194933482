import { Card, Stack, Typography, useTheme } from "@mui/material";

const Component = ({ hours, spacing, sx = {} }) => {
  const businessHours = Object.entries(hours).map(([key, value]) => {
    return (
      <Typography align="center" variant="body1">
        {key}: {value[0]}
      </Typography>
    );
  });

  return (
    <Card sx={{ p: 2, ...sx }} elevation={0}>
      <Stack spacing={spacing || 0}>
        <Typography align="center" variant="h4">
          BUSINESS HOURS
        </Typography>
        <Typography align="center" variant="body1">
          {businessHours}
        </Typography>
      </Stack>
    </Card>
  );
};

export default Component;
