import { API_URL } from "./constants";

import "./App.css";

// refine
import {
  ErrorComponent,
  ReadyPage,
  CssBaseline,
  createTheme,
  ThemeProvider,
  GlobalStyles,
  RefineSnackbarProvider,
  notificationProvider,
} from "@pankod/refine-mui";

// refine utils
import { Refine, Authenticated } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "strapiDataProvider";
import { authProvider } from "authProvider";
import { accessControlProvider } from "accessControlProvider";

// utils
import { AuthProvider } from "contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "utils/axios";

// custom pages and components
import CustomLayout from "components/CustomLayout";
import CustomCreateEdit from "components/CustomCreateEdit";
import LoginPage from "components/user/LoginPage";
import RegisterPage from "components/user/RegisterPage";
import PasswordForgot from "components/user/PasswordForgot";
import PasswordReset from "components/user/PasswordReset";
import Dashboard from "pages/Dashboard";
import Settings from "pages/Settings";
import Unauthorized from "pages/Unauthorized";
import NotFound from "pages/NotFound";
import AccountDeleted from "pages/AccountDeleted";
import Privacy from "pages/Privacy";

// registration requests
import RegistrationsList from "pages/registration/List";
import UserRegistrationRequest from "pages/registration/UserRegistration";
import ViewRegistrationRequest from "components/ViewRegistrationRequest";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";

// resources
import BusinessList from "pages/Businesses/List";
import BusinessFields from "pages/Businesses/Fields";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";

import MessageTemplateList from "pages/MessageTemplates/List";
import MessageTemplateFields from "pages/MessageTemplates/Fields";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";

import MessageList from "pages/Messages/List";
import MessageFields from "pages/Messages/Fields";
import ForumIcon from "@mui/icons-material/Forum";

import WebsitesList from "pages/Websites/List";
import LanguageIcon from "@mui/icons-material/Language";

import CategoryList from "pages/Categories/List";
import CategoryFields from "pages/Categories/Fields";

// Icons

export const themeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#8d99ae",
    },
    secondary: {
      main: "#2b2d42",
    },
    info: {
      main: "#edf2f4",
    },
  },
};

const theme = createTheme(themeOptions);
const queryClient = new QueryClient();
window.queryClient = queryClient;

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Refine
              routerProvider={{
                ...routerProvider,
                routes: [
                  {
                    element: <UserRegistrationRequest />,
                    path: "/registration-request",
                    layout: false,
                  },
                  {
                    element: <ViewRegistrationRequest />,
                    path: "/registration-requests/:id",
                    layout: true,
                  },
                  {
                    element: <PasswordForgot />,
                    path: "/forgot-password",
                    layout: false,
                  },
                  {
                    element: <PasswordReset />,
                    path: "/reset-password",
                    layout: false,
                  },
                  {
                    element: <NotFound />,
                    path: "/not-found",
                    layout: false,
                  },
                  {
                    element: (
                      <Authenticated>
                        <Settings />
                      </Authenticated>
                    ),
                    path: "/settings",
                    layout: true,
                  },
                  // example of authorization with permissions
                  // {
                  //   element: (
                  //     <CanAccess action="customer" fallback={<Unauthorized />}>
                  //       <ProfileView />
                  //     </CanAccess>
                  //   ),
                  //   path: "/profile",
                  //   layout: false,
                  // },
                  {
                    element: <Privacy />,
                    path: "/privacy",
                    layout: false,
                  },
                  {
                    element: <AccountDeleted />,
                    path: "/account-deleted",
                    layout: false,
                  },
                ],
              }}
              resources={[
                {
                  name: "registration-requests",
                  icon: <LiveHelpIcon />,
                  options: {
                    label: "Registration Requests",
                    access: "authenticated",
                  },
                  list: RegistrationsList,
                },
                {
                  name: "businesses",
                  icon: <StoreRoundedIcon />,
                  options: { label: "Businesses", access: "client" },
                  list: BusinessList,
                  create: () =>
                    CustomCreateEdit({
                      isCreate: true,
                      Fields: BusinessFields,
                    }),
                  edit: () =>
                    CustomCreateEdit({
                      isCreate: false,
                      Fields: BusinessFields,
                    }),
                },
                {
                  name: "categories",
                  options: { label: "Lists", access: "client" },
                  list: CategoryList,
                  create: () =>
                    CustomCreateEdit({
                      isCreate: true,
                      Fields: CategoryFields,
                    }),
                  edit: () =>
                    CustomCreateEdit({
                      isCreate: false,
                      Fields: CategoryFields,
                    }),
                },
                {
                  name: "websites",
                  icon: <LanguageIcon />,
                  options: { label: "Websites" },
                  list: WebsitesList,
                  // create: () =>
                  //   CustomCreateEdit({
                  //     isCreate: true,
                  //     Fields: BusinessFields,
                  //   }),
                  // edit: () =>
                  //   CustomCreateEdit({
                  //     isCreate: false,
                  //     Fields: BusinessFields,
                  //   }),
                },
                {
                  name: "message-templates",
                  icon: <EmailRoundedIcon />,
                  options: { label: "Message Templates", access: "client" },
                  list: MessageTemplateList,
                  create: () =>
                    CustomCreateEdit({
                      isCreate: true,
                      Fields: MessageTemplateFields,
                    }),
                  edit: () =>
                    CustomCreateEdit({
                      isCreate: false,
                      Fields: MessageTemplateFields,
                    }),
                },
                {
                  name: "messages",
                  icon: <ForumIcon />,
                  options: { label: "Messages", access: "client" },
                  list: MessageList,
                  edit: () =>
                    CustomCreateEdit({
                      isCreate: false,
                      Fields: MessageFields,
                    }),
                },
              ]}
              authProvider={authProvider}
              accessControlProvider={accessControlProvider}
              dataProvider={DataProvider(API_URL + "/api", axios)}
              notificationProvider={notificationProvider}
              Title={({ collapsed }) => (
                <div
                  style={{
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {collapsed && (
                    <img src="/logo.png" alt="Logo" style={{ width: "50px" }} />
                  )}
                  {!collapsed && (
                    <img
                      src="/logo.png"
                      alt="Logo"
                      style={{ width: "100px" }}
                    />
                  )}
                </div>
              )}
              Layout={CustomLayout}
              DashboardPage={Dashboard}
              ReadyPage={ReadyPage}
              LoginPage={LoginPage}
              catchAll={<ErrorComponent />}
            />
          </AuthProvider>
        </QueryClientProvider>
      </RefineSnackbarProvider>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
