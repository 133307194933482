import { useState, useEffect } from "react";

import { Grid, InputLabel, IconButton } from "@pankod/refine-mui";
import { DeleteForever } from "@mui/icons-material";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./gallery-custom.css";
import UploadZone from "components/UploadZone";

import axios from "utils/axios";
import { toast } from "react-toastify";

const GalleryManager = ({
  control,
  errors,
  setError,
  getValues,
  setValue,
  watch,
  defaultValue,
  fieldName,
  accessor,
  baseUrl,
  viewOnly,
}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (defaultValue) {
      setImages(defaultValue);
    }
    const images = watch(fieldName);
    console.log(accessor);
    if (images) {
      let img = images.map((image) => ({
        ...image,
        original: accessor ? image[accessor] : image.url,
      }));
      setImages(img);
    }
  }, [defaultValue, fieldName, watch]);

  return (
    <Grid
      item
      xs={12}
      sx={{ height: "100%" }}
      container
      justifyContent="center"
      alignItems="center"
      rowSpacing={4}
    >
      <Grid item xs={12} sx={{ height: "80%" }}>
        <ImageGallery
          items={(images || []).map((image) => ({
            id: image.id,
            original: (baseUrl || "") + image.original,
            thumbnail: (baseUrl || "") + image.original,
            createdAt: image.createdAt,
          }))}
          renderItem={(item) => (
            <Grid item xs={12} sx={{ height: "100%" }}>
              <InputLabel sx={{ mt: 2, fontSize: 22 }}>
                {item?.createdAt
                  ? new Date(item.createdAt).toLocaleDateString()
                  : ""}
              </InputLabel>
              {!viewOnly && (
                <IconButton
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: -10,
                    color: "red",
                    width: 50,
                    height: 50,
                  }}
                  onClick={async () => {
                    if (
                      window.confirm(
                        "Sei sicuro di voler rimuovere l'immagine?"
                      )
                    ) {
                      await axios.delete(`/api/upload/files/${item.id}`);
                      setImages(images.filter((image) => image.id !== item.id));
                      toast.success("Immagine rimossa con successo");
                    }
                  }}
                >
                  <DeleteForever sx={{ fontSize: 32 }} />
                </IconButton>
              )}
              <img src={item.original} alt={item.originalAlt} />
            </Grid>
          )}
          showPlayButton={false}
          showFullscreenButton={false}
        />
      </Grid>
      <Grid item sx={{ height: "20%" }}>
        {!viewOnly && (
          <UploadZone
            isButton
            label="Carica Immagine"
            errors={errors}
            setError={setError}
            setValue={setValue}
            onChange={(newImage) => {
              setValue(fieldName, [...(getValues(fieldName) || []), newImage], {
                shouldValidate: true,
              });
            }}
            required
          />
        )}
      </Grid>
    </Grid>
  );
};

export default GalleryManager;
