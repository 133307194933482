import { useMemo, useState } from "react";
import {
  Dialog,
  EditButton,
  DeleteButton,
  Stack,
  Typography,
  useDataGrid,
  DataGrid,
  List,
  Grid,
  TextField,
  LoadingButton,
  IconButton,
  Icon,
} from "@pankod/refine-mui";

import { CheckCircle, Cancel } from "@mui/icons-material";
import WebsiteVisualizer from "templater/WebsiteVisualizer";

import { toast } from "react-toastify";
import axios from "utils/axios";

export default function CompList() {
  const [selectedWebsite, setSelectedWebsite] = useState(null);

  const columns = useMemo(
    () => [
      {
        field: "business",
        headerName: "Business",
        flex: 1,
        minWidth: 10,
        renderCell: function render(params) {
          return <Stack direction="row">{params.row.business.name}</Stack>;
        },
      },
      {
        field: "createdAt",
        headerName: "Found On",
        flex: 1,
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Typography variant="body2">
              {params.row.createdAt.slice(0, 10) +
                " " +
                params.row.createdAt.slice(11, 16)}
            </Typography>
          );
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <IconButton onClick={() => setSelectedWebsite(params.row)}>
                <Icon>visibility</Icon>
              </IconButton>
              {/* <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} /> */}
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: "*",
        sort: [
          {
            field: "id",
            order: "desc",
          },
        ],
      },
    });

  return (
    <List
      resource="websites"
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Websites</Typography>,
      }}
      canCreate={false}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
      <Dialog open={!!selectedWebsite} onClose={() => setSelectedWebsite(null)}>
        {selectedWebsite && (
          <WebsiteVisualizer details={selectedWebsite.website_data} />
        )}
      </Dialog>
    </List>
  );
}
