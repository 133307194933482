import { Grid, Card, Rating, Avatar, Typography } from "@mui/material";

const Component = ({
  review_text,
  rating,
  author_name,
  author_photo_url,
  elevation,
}) => {
  return (
    <Card
      elevation={elevation || 0}
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: 5,
        m: 0,
        p: 2,
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={3} container>
          <Avatar
            alt={author_name}
            src={author_photo_url}
            sx={{
              width: 60,
              height: 60,
              m: 2,
            }}
          />
        </Grid>
        <Grid item xs={9} container justifyContent="flex-end">
          <Typography
            variant="h5"
            sx={{
              ml: 2,
              mt: 2,
            }}
          >
            {author_name}
          </Typography>
          <Rating
            name="read-only"
            value={rating}
            readOnly
            sx={{
              ml: 2,
              mt: 2,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{
                  ml: 2,
                  mt: 2,
                }}
              >
                {review_text}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Component;
