import { useMemo } from "react";
import {
  EditButton,
  DeleteButton,
  Grid,
  Stack,
  Typography,
  useDataGrid,
  DataGrid,
  List,
  LoadingButton,
  IconButton,
  Icon,
} from "@pankod/refine-mui";
import { CheckCircle, Cancel } from "@mui/icons-material";

import { useNavigate } from "@pankod/refine-react-router-v6";
import { authProvider } from "authProvider";

export default function CompList() {
  const user = authProvider.getUserCached();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        field: "message_template",
        headerName: "Type",
        flex: 1,
        minWidth: 100,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <Typography variant="body2">
                {params.row.message_template.name}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "emails",
        headerName: "Emails",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <Typography variant="body2">
                {
                  params.row.business.emails
                    .split(",")
                    .filter((r) => r.length > 0).length
                }
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "business",
        headerName: "Business",
        flex: 1,
        minWidth: 250,
        renderCell: function render(params) {
          return (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Typography variant="body2">
                {params.row.business.name}
              </Typography>
              <IconButton
                onClick={() => {
                  navigate(`/businesses/edit/${params.row.business.id}`);
                }}
              >
                <Icon>open_in_new</Icon>
              </IconButton>
            </Stack>
          );
        },
      },
      {
        field: "send_date",
        headerName: "Send Date",
        flex: 1,
        minWidth: 50,
        renderCell: function render(params) {
          if (params.row.send_date != null) {
            let date = new Date(params.row.send_date);
            let localFormat = date.toLocaleString();
            let dateParts = localFormat.split(",");
            let time = dateParts[1].trim();
            let timeParts = time.split(":");
            let timeString = timeParts[0] + ":" + timeParts[1];
            let dateString = dateParts[0];
            return (
              <Stack
                direction="row"
                justifyContent="center"
                sx={{ width: "100%" }}
              >
                <Typography variant="body2">
                  {dateString + " " + timeString}
                </Typography>
              </Stack>
            );
          }

          return (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <Typography variant="body2">Not Sent</Typography>
            </Stack>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <Typography variant="body2">{params.row.status}</Typography>
            </Stack>
          );
        },
      },
      {
        field: "opened",
        headerName: "Opened",
        flex: 1,
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <Typography variant="body2">
                {params.row.opened ? (
                  <CheckCircle color="success" />
                ) : (
                  <Cancel color="error" />
                )}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "clicked_link",
        headerName: "Clicked Link",
        flex: 1,
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <Typography variant="body2">
                {params.row.clicked_link ? (
                  <CheckCircle color="success" />
                ) : (
                  <Cancel color="error" />
                )}
              </Typography>
            </Stack>
          );
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: "*",
        sort: [
          {
            field: "id",
            order: "desc",
          },
        ],
      },
    });

  return (
    <List
      resource="messages"
      canCreate={false}
      headerProps={{
        title: (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography variant="h5">Messages</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <LoadingButton
                variant="contained"
                fullWidth
                disabled={
                  !user ||
                  !user.sengrid_mail_from ||
                  !user.sendgrid_api_key ||
                  user.sendgrid_webhook_secret
                }
              >
                Send All
              </LoadingButton>
            </Grid>
          </Grid>
        ),
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
