import { useState, useEffect, useRef } from "react";

// Utils ----------------------------------------------------------------------

const useElementOnScreen = (ref, rootMargin = "0px") => {
  const [isIntersecting, setIsIntersecting] = useState(true);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return isIntersecting;
};

const AnimateIn = ({
  from,
  to,
  transition = "600ms ease-in-out",
  delay = "0ms",
  children,
}) => {
  const ref = useRef(null);
  const displaying = useElementOnScreen(ref);
  const [onScreen, setOnScreen] = useState(false);
  useEffect(() => {
    setOnScreen(displaying);
  }, [displaying]);
  const defaultStyles = {
    transition,
    transitionDelay: delay,
  };
  return (
    <div
      ref={ref}
      style={
        onScreen
          ? {
              ...defaultStyles,
              ...to,
            }
          : {
              ...defaultStyles,
              ...from,
            }
      }
    >
      {children}
    </div>
  );
};

// Components -----------------------------------------------------------------

export const FadeIn = ({ children, transition, delay }) => (
  <AnimateIn
    from={{ opacity: 0 }}
    to={{ opacity: 1 }}
    transition={transition}
    delay={delay}
  >
    {children}
  </AnimateIn>
);

export const FadeUp = ({ children, transition, delay }) => (
  <AnimateIn
    from={{ opacity: 0, translate: "0 2rem" }}
    to={{ opacity: 1, translate: "none" }}
    transition={transition}
    delay={delay}
  >
    {children}
  </AnimateIn>
);
export const ScaleIn = ({ children, transition, delay }) => (
  <AnimateIn
    from={{ scale: "0" }}
    to={{ scale: "1" }}
    transition={transition}
    delay={delay}
  >
    {children}
  </AnimateIn>
);

export const PopOnHover = ({
  scaling = "1.01",
  traslation = "0 -5%",
  transition = "200ms ease-in-out",
  children,
}) => {
  const ref = useRef(null);
  const [hovering, setHovering] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [scale, setScale] = useState(1);
  const [translation, setTranslation] = useState("0 0");
  useEffect(() => {
    if (hovering) {
      setScale(scaling);
      setTranslation(traslation);
    } else {
      setScale("1");
      setTranslation("0 0");
    }
  }, [hovering]);
  const defaultStyles = {
    transition,
  };
  return (
    <div
      ref={ref}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={
        hovered
          ? {
              ...defaultStyles,
              scale: scale,
              translate: translation,
            }
          : {
              ...defaultStyles,
              scale,
              translate: translation,
            }
      }
    >
      {children}
    </div>
  );
};
