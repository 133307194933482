import { Create, Edit } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

export default function Comp({ isCreate, Fields }) {
  const {
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    register,
    control,
    formState: { errors },
    getValues,
    setError,
    setValue,
    watch,
  } = useForm({
    refineCoreProps: {
      redirect: false,
    },
  });

  if (isCreate)
    return (
      <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
        <Fields
          register={register}
          control={control}
          errors={errors}
          getValues={getValues}
          setError={setError}
          setValue={setValue}
          watch={watch}
        />
      </Create>
    );

  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Fields
        register={register}
        control={control}
        errors={errors}
        getValues={getValues}
        setError={setError}
        setValue={setValue}
        watch={watch}
      />
    </Edit>
  );
}
