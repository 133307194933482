import { Card } from "@mui/material";

const Component = ({ search }) => {
  return (
    <Card
      sx={{
        width: "100%",
        height: 200,
      }}
    >
      <iframe
        style={{ width: "100%", height: "100%", padding: 0, margin: 0 }}
        frameBorder="0"
        src={`https://www.google.com/maps/embed/v1/place?q=${search}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`}
      ></iframe>
    </Card>
  );
};

export default Component;
