import { useState, useEffect } from "react";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelect({
  name,
  options,
  onChange,
  value,
  labels = [],
  variant,
}) {
  const [selected, setSelected] = useState(value ? value : []);

  useEffect(() => {
    // console.log(value);
    if (value && value.length > 0) {
      if (value[0].id) setSelected(value.map((o) => o.id));
      else setSelected(value);
    }
  }, [value]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelected(value);
    if (onChange) onChange(value);
  };

  const selectedFull = options.filter((option) =>
    selected.includes(option.value)
  );

  // console.log(options, selected);

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-checkbox-label">{name}</InputLabel>
        <Select
          fullWidth
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput label={name} />}
          renderValue={() => _.map(selectedFull, "label").join(", ")}
          MenuProps={MenuProps}
          variant={variant || "standard"}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              <Checkbox
                checked={selected.find((o) => o == option.value) != null}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
