import { useState, useEffect } from "react";

import {
  Grid,
  Card,
  TextField,
  Button,
  IconButton,
  Stack,
  Divider,
  Typography,
  InputAdornment,
} from "@pankod/refine-mui";
import SaveIcon from "@mui/icons-material/Save";

import { toast } from "react-toastify";

import axios from "utils/axios";

export default function Settings() {
  const [user, setUser] = useState(null);

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [sendgridEmail, setSendgridEmail] = useState("");
  const [sendgridApiKey, setSendgridApiKey] = useState("");
  const [sendgridWebhookSecret, setSendgridWebhookSecret] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await axios.get("/api/users/me");
      setUser(data);
      setSendgridEmail(data.sendgrid_mail_from);
      setSendgridApiKey(data.sendgrid_api_key);
      setSendgridWebhookSecret(data.sendgrid_webhook_secret);
    };
    fetchUser();
  }, []);

  return (
    <Grid container spacing={5} justifyContent="space-evenly">
      <Grid item md={5} xs={11} sx={{ mt: -7, mb: 3 }}>
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            p={3}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12}>
              <Grid item xs={12} container justifyContent="center">
                <h1
                  style={{ paddingTop: 10 }}
                >{`Welcome ${user?.username}`}</h1>
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <h3>{user?.email}</h3>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12} container justifyContent="space-evenly">
        {/* ACCOUNT --------------------------------------------------------------------------- */}
        <Grid item md={5} xs={11} mb={2}>
          <Card sx={{ borderRadius: 5, height: "100%" }}>
            <Grid
              item
              xs={12}
              p={3}
              container
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <h1>Sendgrid</h1>
              <Grid item xs={12} sx={{ padding: 1, width: "100%" }}>
                <Stack
                  direction="column"
                  spacing={2}
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <TextField
                    fullWidth
                    label="Sendgrid Email"
                    value={sendgridEmail || ""}
                    onChange={(e) => setSendgridEmail(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            onClick={async () => {
                              try {
                                await axios.put("/api/users/" + user.id, {
                                  sendgrid_mail_from: sendgridEmail,
                                });
                                toast.success("Sendgrid Email Updated");
                              } catch (error) {
                                console.log(error);
                                toast.error("Error Updating Sendgrid Email");
                              }
                            }}
                          >
                            <SaveIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Sendgrid API Key"
                    value={sendgridApiKey || ""}
                    onChange={(e) => setSendgridApiKey(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            onClick={async () => {
                              try {
                                await axios.put("/api/users/" + user.id, {
                                  sendgrid_api_key: sendgridApiKey,
                                });
                                toast.success("Sendgrid API Key Updated");
                              } catch (error) {
                                console.log(error);
                                toast.error("Error Updating Sendgrid API Key");
                              }
                            }}
                          >
                            <SaveIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Sendgrid Web Hook Secret"
                    value={sendgridWebhookSecret || ""}
                    onChange={(e) => setSendgridWebhookSecret(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            onClick={async () => {
                              try {
                                await axios.put("/api/users/" + user.id, {
                                  sendgrid_webhook_secret:
                                    sendgridWebhookSecret,
                                });
                                toast.success(
                                  "Sendgrid Web Hook Secret Updated"
                                );
                              } catch (error) {
                                console.log(error);
                                toast.error(
                                  "Error Updating Sendgrid Web Hook Secret"
                                );
                              }
                            }}
                          >
                            <SaveIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {/* PASSWORD --------------------------------------------------------------------- */}
        <Grid item md={5} xs={11} mb={2}>
          <Card sx={{ borderRadius: 5, height: "100%" }}>
            <Grid
              item
              xs={12}
              container
              alignItems="space-between"
              justifyContent="center"
              p={3}
              sx={{ height: "100%" }}
            >
              <h1>Change Password</h1>
              <Grid item xs={12} sx={{ padding: 1, width: "100%" }}>
                <Stack
                  direction="column"
                  spacing={2}
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    label="Current Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                  />
                  <Divider
                    sx={{
                      width: "100%",
                      my: 1,
                    }}
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    label="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    variant="outlined"
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      if (newPassword === confirmPassword) {
                        try {
                          let res = await axios.post(
                            "/api/auth/change-password",
                            {
                              currentPassword: password,
                              password: newPassword,
                              passwordConfirmation: confirmPassword,
                            }
                          );
                          console.log(res.data);
                          toast.success("Password Updated");
                        } catch (error) {
                          console.log(error);
                          toast.error("Error Updating Password");
                        }
                        setPassword("");
                        setNewPassword("");
                        setConfirmPassword("");
                      }
                    }}
                  >
                    Update Password
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="space-evenly" mb={2}></Grid>
    </Grid>
  );
}
