import { Grid, Button, useTheme } from "@mui/material";

const Component = ({ text, href }) => {
  const theme = useTheme();
  return (
    <Grid container justifyContent="center">
      <Button
        variant="contained"
        size="large"
        sx={{
          mb: 2,
          backgroundColor: theme.secondary.main,
          "&:hover": {
            backgroundColor: theme.secondary.light,
          },
        }}
        href={href}
        target="_blank"
      >
        {text}
      </Button>
    </Grid>
  );
};

export default Component;
