import { useState, useEffect, useMemo } from "react";
import { Typography, Card } from "@pankod/refine-mui";
import { DataGrid } from "@mui/x-data-grid";

export default function ({ columns, data, loading }) {
  const [pageSize, setPageSize] = useState(10);

  return (
    <DataGrid
      columns={columns}
      rows={data || []}
      loading={loading}
      rowsPerPageOptions={[10, 25, 50]}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      autoHeight
      sx={{
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: "#f5f5f5",
        },

        "& .MuiDataGrid-cell": {
          borderRight: "0x",
        },

        "& .MuiDataGrid-columnsContainer": {
          borderBottom: "0px",
        },

        "& .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
      }}
    />
  );
}
