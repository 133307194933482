import { Card, Stack, Typography, useTheme } from "@mui/material";

const Component = ({ title, text, spacing, sx = {} }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{ p: 2, backgroundColor: theme.secondary.light, ...sx }}
      elevation={0}
    >
      <Stack spacing={spacing || 0}>
        <Typography align="center" variant="h4">
          {title}
        </Typography>
        <Typography
          align="center"
          variant="body1"
          sx={{ whiteSpace: "pre-line" }}
        >
          {text}
        </Typography>
      </Stack>
    </Card>
  );
};

export default Component;
