import NavBar from "./NavBar";
import { Grid } from "@mui/material";

const Layout = ({
  title,
  logo,
  logoVariant,
  links,
  alignMenu = "flex-start",
  boxed,
  centered,
  children,
}) => {
  return (
    <Grid item xs={12}>
      {/* <NavBar
        title={title}
        image={logo}
        links={links}
        logoVariant={logoVariant}
        alignMenu={alignMenu}
        boxed={boxed}
        centered={centered}
      /> */}
      {children}
    </Grid>
  );
};

export default Layout;
