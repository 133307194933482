import * as React from "react";

import { Container, Stack, Typography, Grid, Rating } from "@mui/material";

import Layout from "./layout/Layout";
import { FadeUp, PopOnHover } from "./components/Animate";
import BannerCarousel from "./components/BannerCarousel";
import TitledCard from "./components/TitledCard";
import OrderButton from "./components/OrderButton";
import BusinessHours from "./components/BusinessHours";
import Review from "./components/Review";
import StyledDivider from "./components/StyledDivider";
import GoogleMaps from "./components/GoogleMaps";
import ContactIcon from "./components/ContactIcon";

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// A custom theme for this app
let theme = createTheme({
  primary: {
    main: "#fffff2",
  },
  secondary: {
    main: "#19857b",
    light: "#a3ccc7",
  },
  typography: {
    allVariants: {
      fontFamily: ["Karla", "sans-serif"].join(","),
    },
    h2: {
      color: "#19857b",
    },
    h5: {
      color: "#19857b",
    },
    h6: {
      color: "#19857b",
    },
  },
});

theme = responsiveFontSizes(theme);

export default function Templater({ details }) {
  const links = [
    {
      name: "Home",
      href: "#",
    },
    {
      name: "About",
      href: "#about",
    },
    {
      name: "Reviews",
      href: "#reviews",
    },
    {
      name: "Contact",
      href: "#contact",
    },
  ];
  console.log(details);

  const logo = details.photos_sample[0].photo_url;
  const reviewsToShow = details.reviews.reviews.filter(
    (review) => review.rating >= 4
  );
  let contacts = Object.entries(details.emails_and_contacts).map(
    ([key, value]) => {
      if (!Array.isArray(value))
        return {
          contactType: key,
          href: value,
        };
    }
  );
  contacts = contacts.filter((contact) => contact !== undefined);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout
        logo={logo}
        title={details.name}
        alignMenu="flex-end"
        links={links}
        boxed={false}
        centered={true}
      >
        <FadeUp transition="250ms ease-in-out">
          <BannerCarousel
            images={details.photos.map((item) => item.photo_url)}
            elevation={10}
            marginTop={2}
          />
        </FadeUp>
        <Container sx={{ my: 4 }}>
          <Stack spacing={4}>
            <FadeUp transition="250ms ease-in-out" delay="150ms">
              <Typography align="center" variant="h2">
                {details.name}
              </Typography>
              <StyledDivider />
            </FadeUp>

            {details.order_link && (
              <FadeUp transition="250ms ease-in-out" delay="200ms">
                <OrderButton text="ORDER NOW" href={details.order_link} />
              </FadeUp>
            )}

            <FadeUp transition="250ms ease-in-out" delay="200ms">
              <BusinessHours hours={details.working_hours} spacing={3} />
            </FadeUp>

            <FadeUp transition="250ms ease-in-out" delay="300ms">
              <PopOnHover traslation="0 -1%" scaling="1.005">
                <div id="about" />
                <TitledCard title="ABOUT" text={details.about} spacing={3} />
              </PopOnHover>
            </FadeUp>

            <FadeUp transition="250ms ease-in-out" delay="200ms">
              <Typography
                align="center"
                variant="h5"
                sx={{ mt: 3 }}
                id="reviews"
              >
                REVIEWS
              </Typography>
              <StyledDivider />
            </FadeUp>

            <FadeUp transition="250ms ease-in-out" delay="200ms">
              <Typography
                align="center"
                variant="h6"
                sx={{ mb: 3 }}
                id="reviews"
              >
                OVERALL
              </Typography>
              <Grid container justifyContent="center">
                <Rating
                  value={details.reviews.rating}
                  readOnly
                  size="large"
                  precision={0.1}
                />
              </Grid>
            </FadeUp>

            <Grid container rowSpacing={2} justifyContent="space-evenly">
              {reviewsToShow.map((review, index) => (
                <Grid item xs={12} key={index}>
                  <FadeUp transition="250ms ease-in-out" delay="200ms">
                    <PopOnHover traslation="0 -1%" scaling="1.005">
                      <Review
                        elevation={3}
                        review_text={review.review_text}
                        rating={review.rating}
                        author_name={review.author_name}
                        author_photo_url={review.author_photo_url}
                      />
                    </PopOnHover>
                  </FadeUp>
                </Grid>
              ))}
            </Grid>

            <FadeUp transition="250ms ease-in-out" delay="200ms">
              <Typography
                align="center"
                variant="h5"
                sx={{ mt: 3 }}
                id="contact"
              >
                WHERE TO FIND US
              </Typography>
              <StyledDivider />

              <Typography align="center" variant="h6" sx={{ my: 2 }}>
                {details.address}
              </Typography>

              <GoogleMaps
                search={
                  details.name +
                  " " +
                  details.address +
                  " " +
                  details.city +
                  "," +
                  details.country
                }
              />

              {/* <Typography align="center" variant="h6" sx={{ my: 2 }} component="a" href={}>
                GET DIRECTIONS
              </Typography> */}
            </FadeUp>

            <FadeUp transition="250ms ease-in-out" delay="200ms">
              <Typography
                align="center"
                variant="h5"
                sx={{ mt: 3 }}
                id="contact"
              >
                CONTACT US
              </Typography>
              <StyledDivider />
              <Grid container spacing={2} justifyContent="space-evenly">
                {contacts.map((contact, index) => (
                  <ContactIcon
                    key={index}
                    contactType={contact.contactType}
                    href={contact.href}
                  />
                ))}
              </Grid>
            </FadeUp>
          </Stack>
        </Container>
      </Layout>
    </ThemeProvider>
  );
}
