import * as React from "react";

import useTheme from "@mui/material/styles/useTheme";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";

import DrawerComponent from "./Drawer";

const pages = [
  { name: "Products", href: "#" },
  { name: "Pricing", href: "#" },
  { name: "Blog", href: "#" },
];

function NavBar({
  title,
  image,
  logoVariant,
  alignMenu,
  links,
  boxed,
  centered,
}) {
  const theme = useTheme();
  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: theme.primary.main,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <DrawerComponent
              links={links || pages}
              boxed={boxed}
              centered={centered}
            />
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: "flex",
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: alignMenu,
            }}
          >
            {(links || pages).map((page, index) => (
              <Typography
                noWrap
                component="a"
                key={index}
                href={page.href}
                variant="h6"
                sx={{
                  textDecoration: "none",
                  cursor: "pointer",
                  mx: 1,
                  "&:hover": {
                    color: theme.secondary.light,
                  },
                }}
              >
                {page.name}
              </Typography>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
